import { ActionContext } from '../../cra';
import MCNavigationComponent from '../../components/form/mc/mcNavigation';
import React from 'react';
import Vigil from "@foxsenseinnovations/vigil-sdk-js";

class IndexPage extends React.Component {
  static contextType = ActionContext
  componentDidMount() {
    this.context.trackCurrentPage('MA Page');
    Vigil.initialize({
      apiKey: process.env.GATSBY_VIGIL_API_KEY ? process.env.GATSBY_VIGIL_API_KEY : "",
      clientVersion: process.env.GATSBY_VIGIL_CLIENT_VERSION ? process.env.GATSBY_VIGIL_CLIENT_VERSION : "" 
  });
  }
  render() {
    return <MCNavigationComponent isMedicareAdvantage={true} />;
  }
}

export default IndexPage;
